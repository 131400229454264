export const authenticated_client = [
  '_id',
  'username',
  'email',
  'fname',
  'lname',
  'terms',
  'gender',
  'account_limit',
  'ispremiumaccount',
  'isprofessionalaccount',
];

export const account_details = [
  'format_used_storage',
  'total_directories',
  'total_files',
  'total_images',
  'used_storage',
];

export const settingsPrivacyOpts = ['optIndex', 'value'];

export const settings = [
  'followNotify',
  'likesNotify',
  'privateAccount',
  { settingsPrivacyOpts },
];
