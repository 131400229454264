export enum EAppRoutes {
  ROOT = '/',
  APP = 'app',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  ADMIN = 'admin',
  ORDER = 'order',
  SIGNIN = 'signin',
}

export const EAppFullRoutes = {
  APP: [EAppRoutes.ROOT, EAppRoutes.APP],
  SIGNIN: [EAppRoutes.ROOT, EAppRoutes.SIGNIN],
  DASHBOARD: [EAppRoutes.ROOT, EAppRoutes.APP, EAppRoutes.DASHBOARD],
  LANDING: [EAppRoutes.ROOT, EAppRoutes.DASHBOARD],
};
