import {
  Injectable,
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  ShareErrorModuleInterceptor,
  ShareModuleInterceptorProvider,
} from './share-error.interceptor';
import { LoggerService } from '../components/logger';

export const HTTP_CONTEXT_TOKEN = new HttpContextToken(() => 'DEFAULT');

@Injectable({ providedIn: 'root' })
export class HttpClientModuleInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState,
    private debuggerService: LoggerService,
    private shareErrorModuleInterceptor: ShareErrorModuleInterceptor,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      withCredentials: true,
      reportProgress: true,
    });

    console.log('interceptor');

    const logger = new BehaviorSubject(null);
    logger.subscribe((res) => {
      this.debuggerService.log(`${newReq.method} : ${newReq.url}`, newReq.body);
      this.debuggerService.info(`RESPONSE => `, res);
    });

    const key: StateKey<string> = makeStateKey(JSON.stringify(req.body));
    const storedResponse: string = this.transferState.get<string>(key, null);
    if (storedResponse) {
      this.transferState.remove(key);
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      this.debuggerService.log(newReq.url, storedResponse);

      return of(response);
    }

    return next.handle(newReq).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const { body } = event;
          if (body?.errors) {
            throw new Error(JSON.stringify(body?.errors));
          } else {
            logger.next(body);
            logger.complete();
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof Error && error?.message) {
          try {
            error = JSON.parse(error.message);
          } catch (e) {}
        }

        this.debuggerService.error(`ERROR =>`, error);
        return this.shareErrorModuleInterceptor.catchError(error);
      }),
    );
  }
}

export let HttpClientModuleInterceptorProvider: any = [
  ...ShareModuleInterceptorProvider,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpClientModuleInterceptor,
    multi: true,
  },
];
