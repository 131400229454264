import { APP_INITIALIZER, Injectable } from '@angular/core';
import { LoggerService, TLoggerQueryParams } from './logger.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter, forkJoin } from 'rxjs';
import { LOGGER } from './logger.component';
import { LocalStorageService } from 'src/app/services';

@Injectable({ providedIn: 'root' })
export class LoggerInitialize {
  constructor(
    private loggerService: LoggerService,
    private localstorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  load(): void {
    this.route.queryParams
      .pipe(filter((d) => d.hasOwnProperty(LOGGER)))
      .subscribe((d: TLoggerQueryParams) => {
        this.localstorage.set(LOGGER, d);
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loggerService.info(
          `PAGE URL: ${event.url} ========================>`,
        );
      }
    });
  }
}

export function loggerInitializerFactory(loggerInitialize: LoggerInitialize) {
  return () => loggerInitialize.load();
}

export const LoggerInitializerProvider = [
  LoggerInitialize,
  {
    provide: APP_INITIALIZER,
    useFactory: loggerInitializerFactory,
    deps: [LoggerInitialize],
    multi: true,
  },
];
