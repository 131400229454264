import { Injectable, signal } from '@angular/core';
import { get, set, unset } from 'lodash';

export const AppStateSignal = signal({});

@Injectable({ providedIn: 'root' })
export class AppState {
  constructor() {}

  // already return a clone of the current state
  public get state() {
    return AppStateSignal();
  }

  // never allow mutation
  public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public flush() {
    AppStateSignal.set({});
  }

  /**
   * Delete the key from memory, this will not search within nested key reference, only the parent key
   * @param key
   */
  public remove(key: string) {
    const obj = this.state;
    unset(obj, key);
    AppStateSignal.update((value) => {
      return obj;
    });
  }

  public get(key: string, fallback = undefined) {
    const obj = this.state;
    return key ? get(obj, key, fallback) || fallback : obj;
  }

  public set(key: string, value: any) {
    AppStateSignal.update((obj) => {
      return set(obj, key, value);
    });
    return this.state;
  }
}
