import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take, throwError, timer } from 'rxjs';
import { EErrorMessages, ErrorMessagesRoutes } from '../models';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ShareErrorModuleInterceptor {
  private redirecting: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
  ) {}

  private redirectTo(url: string) {
    if (this.redirecting) return;
    this.redirecting = true;
    timer(0)
      .pipe(take(1))
      .subscribe((d) => {
        this.router.navigateByUrl(url).then((d) => {
          this.redirecting = false;
        });
      });
  }

  // TODO: global error message
  catchError(e) {
    if (Array.isArray(e)) {
      const ErrorMessages: string[] = e.map((a) => {
        return (a?.extensions ?? a).message?.toLowerCase();
      });

      const REDIRECT =
        ErrorMessagesRoutes?.[
          ['UNAUTHORIZED', 'FORBIDDEN'].filter((r) =>
            ErrorMessages.includes(EErrorMessages[r].toLowerCase()),
          )?.[0]
        ];

      /**
       * Redirect to either signin or root path,
       * if we are not already there.
       */
      if (REDIRECT && !REDIRECT.contains.includes(this.router.url)) {
        this.redirectTo(REDIRECT.redirect);
      }
    }

    if (e instanceof HttpErrorResponse) {
      const { status, error } = e;
      switch (status) {
        case 404:
          this.router.navigateByUrl('oops/404').then();
          break;
        default:
          e = error?.errors ?? e;
          break;
      }
    }

    return throwError(() => e);
  }
}

export let ShareModuleInterceptorProvider: any = [ShareErrorModuleInterceptor];
