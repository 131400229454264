import { BackgroundTaskJob } from '../models/background-task.model';
import { isProduction } from '@app/environment';
import { Observable, finalize, fromEvent } from 'rxjs';

export const AvailableWorkers = [
  {
    worker: XHRHttpRequestWorker,
    name: 'XHRHttpRequestWorker',
  },
];

function XHRHttpRequestWorker(job: BackgroundTaskJob) {
  const worker = new Worker(
    new URL('./xhrHttpRequest.worker', import.meta.url),
    { type: 'module' },
  );
  const worker$ = fromEvent(worker, 'message');
  return new Observable((obs) => {
    worker$.subscribe(({ data }: any) => {
      obs.next(data);
      obs.complete();
    });
    worker.postMessage(job);
  }).pipe(
    finalize(() => {
      if (isProduction) {
        worker.terminate();
      }
    }),
  );
}

export function defineWorker(workerInit: {
  worker: (arg: any) => Observable<any>;
  name: string;
}) {
  AvailableWorkers.push(workerInit);
  return AvailableWorkers;
}
