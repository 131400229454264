import {
  ApplicationRef,
  Component,
  ComponentRef,
  createComponent,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

export type Content<T> =
  | string
  | TemplateRef<T>
  | Type<T>
  | HTMLElement
  | ComponentRef<any>;

@Component({
  standalone: true,
  selector: 'ng-content-component',
  template: '',
})
export class ResolveNgContentComponent {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private applicationRef: ApplicationRef,
  ) {}
}

//@dynamic
export class ViewContainerRefComponent {
  private static _instance: ViewContainerRefComponent;
  public viewContainerRef: ViewContainerRef;

  setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

@Injectable({ providedIn: 'root' })
export class ViewContainerRefService {
  get viewContainerRef() {
    return ViewContainerRefComponent.Instance.viewContainerRef;
  }

  set viewContainerRef(ref: ViewContainerRef) {
    ViewContainerRefComponent.Instance.setViewContainerRef(ref);
  }
}

export function isHTML(str) {
  const a = document.createElement('div');
  a.innerHTML = str;
  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
}

export function resolveNgContent<T>(content: Content<T>): {
  ngContext: any[][];
  componentRef?: any;
} {
  if (!content) content = '';
  const ishtml = typeof content === 'string' && isHTML(content);
  if (!ishtml && typeof content === 'string') {
    const element: Text = document.createTextNode(content as any);
    return {
      ngContext: [[element]],
    };
  } else if (ishtml) {
    const a: any = document.createElement('div');
    a.innerHTML = content;

    if (a instanceof HTMLElement) content = a;
  }

  if (content instanceof HTMLElement) {
    return {
      ngContext: [[content]],
    };
  }
  const { viewContainerRef } = getRefs.apply(this, []);

  /** Otherwise it's a component */

  if (content instanceof TemplateRef) {
    const viewRef: any = viewContainerRef.createEmbeddedView(content as any);
    return {
      ngContext: [viewRef.rootNodes],
    };
  }

  const componentRef = viewContainerRef.createComponent(content);
  return {
    componentRef,
    ngContext: [[componentRef.location.nativeElement]],
  };
}

/**
 * Creates a dummy Component to get the ViewContainerRef, and ApplicationRef
 */
async function getComponentRef() {
  const popup = document.createElement('resolveNgContent');
  if (this.hasOwnProperty('applicationRef')) {
    const environmentInjector = this.applicationRef.injector;
    return createComponent(ResolveNgContentComponent, {
      hostElement: popup,
      environmentInjector,
    });
  } else {
    return { instance: null, hostView: null };
  }
}

function getRefs() {
  const hasViewContainerRef = this.hasOwnProperty('viewContainerRef');
  const hasInjector = this.hasOwnProperty('injector');

  if (!hasInjector && !hasViewContainerRef)
    throw Error('required [viewContainerRef, injector]');
  const { instance, hostView } = <ComponentRef<any>>(
    (<unknown>getComponentRef.apply(this, []))
  );

  const viewContainerRef = hasViewContainerRef
    ? this.viewContainerRef
    : instance['viewContainerRef'];
  const applicationRef = instance ? instance['applicationRef'] : null;
  if (applicationRef) applicationRef.attachView(hostView);

  return { viewContainerRef, applicationRef, hostView };
}
