import { EGrantAccessType, EPermissions } from '@ponylagoon/dto';

export const restricted = ['email'];

export const publishModel = [
  'createdOn',
  'expiresIn',
  'expiresOn',
  'file_id',
  'grant_access',
  { restricted },
  'row_id',
  'url',
];

export interface PublishSearch {
  share_link?: string;
  file_id?: string;
  row_id?: string;
  username?: string;
}

export interface RestrictedPolicy {
  email?: string;
  permission?: EPermissions;
  user_id?: string;
}

export interface PublishPolicy {
  restricted?: RestrictedPolicy[];
  emails?: string[];
  row_id?: string;
  expiresIn?: string;
  expiresOn?: string;
  file_id?: string;
  url?: string;
  allowed?: boolean;
  grant_access?: EGrantAccessType;
}
