import {
  APP_INITIALIZER,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE } from './default-language.constants';
import * as enUS from '../assets/i18n/en-US.json';

@Injectable({ providedIn: 'root' })
export class AppInitialize {
  private renderer: Renderer2;

  constructor(
    private translate: TranslateService,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  load(): void {
    this.translate.addLangs([DEFAULT_LANGUAGE]);
    this.translate.setTranslation(DEFAULT_LANGUAGE, enUS);
    this.translate.use(DEFAULT_LANGUAGE);
  }
}

export function appInitializerFactory(appInitialize: AppInitialize) {
  return () => appInitialize.load();
}

export const AppInitializerProvider = [
  AppInitialize,
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [AppInitialize],
    multi: true,
  },
];
