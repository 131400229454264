import { Injectable } from '@angular/core';

import { GraphqlService } from '../graphql';
import {
  publishModel,
  PublishPolicy,
  PublishSearch,
} from '../graphql/fields/permission';
import { Observable } from 'rxjs';
import Fields from 'gql-query-builder/build/Fields';
import { AppState } from '../app.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private graphqlService: GraphqlService,
    private http: HttpClient,
    private appState: AppState,
  ) {}

  getFilePermissions(
    search: PublishSearch,
    fields: Fields = [],
  ): Observable<PublishPolicy[]> {
    return this.graphqlService.query({
      operation: 'getFilePermissions',
      fields: [...publishModel, ...fields],
      variables: {
        search: { value: search, required: true, type: 'PublishSearch' },
      },
    });
  }

  ping() {
    return this.http.post('/api/ping', {});
  }

  authToken(token) {
    return this.graphqlService.query({
      operation: 'auth',
      fields: ['jwt'],
      variables: {
        token: {
          value: token,
          required: true,
        },
      },
    });
  }
}
