import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EAppRoutes } from './models';
import { AlreadyLoggedInResolver } from './resolvers/alreadyloggedin.resolver';
// import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./containers/signin/signin-entry.module').then(
        ({ SigninEntryModule }) => SigninEntryModule,
      ),
  },
  {
    path: EAppRoutes.DASHBOARD,
    resolve: {
      AlreadyLoggedInResolver,
    },
    loadChildren: () =>
      import('./containers/reports/reports.module').then(
        ({ ReportsModule }) => ReportsModule,
      ),
  },
  {
    path: EAppRoutes.REPORTS,
    resolve: {
      AlreadyLoggedInResolver,
    },
    loadChildren: () =>
      import('./containers/reports/reports.module').then(
        ({ ReportsModule }) => ReportsModule,
      ),
  },
  {
    path: EAppRoutes.ADMIN,
    resolve: {
      AlreadyLoggedInResolver,
    },
    loadChildren: () =>
      import('./containers/admin/admin.module').then(
        ({ AdminModule }) => AdminModule,
      ),
  },
  {
    path: EAppRoutes.ORDER,
    resolve: {
      AlreadyLoggedInResolver,
    },
    loadChildren: () =>
      import('./containers/order/order.module').then(
        ({ OrderModule }) => OrderModule,
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
