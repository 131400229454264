import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppInitializerProvider } from './app.initializer.provider';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntryComponent } from './entry.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpClientModuleInterceptorProvider } from './interceptors';
import { AppServiceProviders } from './services';
import { GraphqlService } from './graphql';
import { AlreadyLoggedInResolverService } from './resolvers/alreadyloggedin.resolver';
import { DatePipe } from '@angular/common';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '6d5db43d-371f-4632-8cc4-e10f3f412482', // This is your client ID
      authority:
        'https://login.microsoftonline.com/392ac87d-44f1-405a-955a-0293854cd993',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    `/api/auth`,
    // `https://camp-nestjs.test.site-testing.com/api/v1/auth/login`,
    ['api://1d7878f2-703e-49f5-a141-a057a90ed688/access_as_user'],
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['api://1d7878f2-703e-49f5-a141-a057a90ed688/access_as_user'],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [EntryComponent, AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    MsalModule,
  ],
  providers: [
    ...HttpClientModuleInterceptorProvider,
    ...AppInitializerProvider,
    ...AppServiceProviders,
    GraphqlService,
    AlreadyLoggedInResolverService,
    DatePipe,
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
})
export class AppModule {}
