export enum IWorkerTaskStatus {
  TERMINATED = 'TERMINATED',
  RUNNING = 'RUNNING',
}

export interface IWorkerTaskUploadPayload {
  keepOpen: boolean;
  filestream: string;
  override: boolean;
  file_id: string;
}

export interface IWorkerTask {
  taskId: string;
  payload: IWorkerTaskUploadPayload;
  taskStatus: IWorkerTaskStatus;
  progress: {
    totalJobs: number;
    doneJobs: number;
    progressPercent: number;
  };
}
