export const fileAttributes = [
  '_id',
  'originalname',
  'lastModifiedDate',
  'contentDisposition',
  'key',
  'starred',
  'mimetype',
  'status',
];

export const keyPath = ['_id', 'originalname'];

export const photoSwipe = ['width', 'height', 'src', 'format_size'];

export const images = [
  '_id',
  'format_size',
  'width',
  'height',
  'originalname',
  'profile',
  'public',
  'src',
];
