<ng-container *ngIf="showLogger">
  <div
    class="loggerr-header align-items-center debugger-header p-1 d-flex justify-content-between"
  >
    <button
      (click)="detach()"
      type="button"
      class="btn btn-sm btn-danger text-uppercase rounded-0"
    >
      <fa-icon [icon]="faUnlink" class=""></fa-icon>
    </button>
    <span>{{ url }}</span>

    <div class="">
      <button
        class="btn btn-default btn-sm ripple px-2 me-2"
        (click)="download()"
      >
        <fa-icon [icon]="faFileDownload" class=""></fa-icon>
      </button>
      <button
        type="button"
        (click)="openLogger = !openLogger"
        class="btn btn-sm btn-secondary text-uppercase rounded-0"
      >
        logs
      </button>
    </div>
  </div>
  <div #scroller id="scroller" class="debugger-body overflow-auto h-100">
    <div
      *ngFor="let log of logs"
      [ngClass]="this.logTypes[log?.type]"
      class="message p-1"
    >
      {{ log?.message }}
    </div>
    <div #anchor id="anchor" class="w-100">
      <button
        (click)="clearLogs()"
        type="button"
        style="font-size: 10px; color: aliceblue"
        class="p-1 btn btn-sm btn-link text-uppercase rounded-0 float-end m-1"
      >
        Clear
      </button>
    </div>
  </div>
</ng-container>
