import { inject, Injectable } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { ShareErrorModuleInterceptor } from '../interceptors/share-error.interceptor';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class AlreadyLoggedInResolverService {
  constructor(
    private permissionService: PermissionService,
    private sharedError: ShareErrorModuleInterceptor,
  ) {}

  resolve(): Observable<any> | Promise<any> | any {
    // return  this.permissionService.ping()
    return {};
  }
}

export const AlreadyLoggedInResolver: ResolveFn<any> = () => {
  return inject(AlreadyLoggedInResolverService).resolve();
};
