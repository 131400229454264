export class Listener {
  private _listeners = [];

  public add(fn: () => void): Listener {
    this._listeners.push(fn);
    return this;
  }

  public unsubscribe() {
    this._listeners.forEach((fn) => fn());
  }
}
