import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { LocalStorageService } from 'src/app/services';
import {
  LoggerService,
  TLoggerQueryParams,
  TMessageLog,
} from './logger.service';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, filter, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { faFileDownload, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { saveAs } from 'file-saver';

export const LOGGER: string = 'logger';

@Component({
  selector: '[debugger]',
  templateUrl: `./logger.component.html`,
  styleUrls: [`./logger.component.scss`],
  host: {
    '[class.invisible]': '!showLogger',
    '[class.fixed-bottom]': 'showLogger',
    '[class.w-100]': 'showLogger',
    '[class.d-flex]': 'showLogger',
    '[class.flex-column]': 'showLogger',
    '[class.h-100]': 'showLogger',
  },
})
export class LoggerComponent implements OnInit {
  @ViewChild('scroller')
  public scroller: ElementRef;

  @ViewChild('anchor')
  public anchor: ElementRef;

  @HostBinding('class.show')
  public openLogger: boolean = false;

  public faFileDownload = faFileDownload as IconProp;
  public faUnlink = faUnlink as IconProp;

  public showLogger: boolean = false;
  public logs: TMessageLog[] = [];
  public url: string = '';

  public logTypes = {
    log: '',
    info: 'text-info',
    error: 'text-danger',
  };

  private isReady = new BehaviorSubject(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private localstorage: LocalStorageService,
    private debuggerService: LoggerService,
    private route: ActivatedRoute,
  ) {
    const logger = this.localstorage.get<TLoggerQueryParams>(LOGGER)?.logger;
    this.showLogger = logger === 'true';
  }

  detach() {
    this.localstorage.remove(LOGGER);
    this.showLogger = false;
  }

  clearLogs() {
    this.debuggerService.flush();
    this.logs = this.debuggerService.Logs;
  }

  download() {
    const logs = Array.from(this.logs)
      .filter((a) => a)
      .map((a) => {
        return JSON.stringify(JSON.parse(a.message));
      })
      .join('\n');
    const blob = new Blob([logs], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${new Date().toISOString()}.txt`);
  }

  ngAfterViewInit() {
    if (!this.showLogger) return;
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.Scroller) return;
    this.isReady.next(true);
  }

  get Anchor(): HTMLElement {
    return this.anchor?.nativeElement;
  }

  get Scroller(): HTMLElement {
    return this.scroller?.nativeElement;
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    //http://localhost:3000/app/dashboard?logger=true&url=google.com

    this.route.queryParams
      .pipe(filter((d) => d.hasOwnProperty(LOGGER)))
      .subscribe((d: TLoggerQueryParams) => {
        this.localstorage.set(LOGGER, d);
        this.showLogger = d?.logger === 'true';
        this.print((this.logs = this.debuggerService.Logs));
        timer(1).subscribe((d) => this.ngAfterViewInit());
      });

    this.isReady.pipe(filter((a) => a && this.showLogger)).subscribe((a) => {
      this.debuggerService.onMessages((log: TMessageLog) => {
        this.logs.push(log);
        this.print(log);
        timer(1)
          .pipe(filter((a) => !!this.Scroller))
          .subscribe((d) => {
            this.Scroller.scrollTo(0, this.Scroller.scrollHeight);
          });
      });
    });
  }

  print(log: TMessageLog | TMessageLog[]) {
    const print = this.localstorage.get<TLoggerQueryParams>(LOGGER)?.print;
    const allow = print === 'true';
    if (!allow) return;
    const logs = Array.isArray(log) ? log : [log];
    logs.forEach((d) => console.log(d));
  }
}
