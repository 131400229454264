import { EAppFullRoutes, EAppRoutes } from './EAppRoutes';

export enum EErrorMessages {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
}

export const ErrorMessagesRoutes = {
  UNAUTHORIZED: {
    contains: [EAppFullRoutes.SIGNIN],
    redirect: EAppFullRoutes.SIGNIN,
  },
  FORBIDDEN: {
    contains: [EAppFullRoutes.SIGNIN],
    redirect: EAppFullRoutes.SIGNIN,
  },
};
