import { Component } from '@angular/core';
@Component({
  selector: 'entry',
  template: `
    <div class="fixed-top bgc-white"></div>
    <div
      role="main"
      class="main-container flex-column h-100"
      style="padding-top: var(--navbar-sm-height)"
    >
      <router-outlet></router-outlet>
    </div>
  `,
  host: {
    class: 'h-100',
  },
})
export class EntryComponent {}
