import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

// polyfill for server
const localStorageServer = {
  removeItem: () => null,
  getItem: () => null,
  clear: () => null,
  setItem: () => null,
};

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  flush() {
    try {
      this.localStorage.clear();
    } catch (e) {}
  }

  get<T>(key: string, fallback?: T): T {
    let stored = this.localStorage.getItem(key) ?? '';
    try {
      stored = JSON.parse(stored);
    } catch (e) {}

    return <T>(<unknown>(stored ?? fallback));
  }

  set(key: string, value: string | object) {
    value = JSON.stringify(value);
    try {
      this.localStorage.setItem(key, value);
    } catch (e) {}

    return value;
  }

  remove(key: string) {
    try {
      this.localStorage.removeItem(key);
    } catch (e) {}
  }

  private get localStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return window.localStorage;
    }
    return localStorageServer;
  }
}
